.framePage-halfBody {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.framePage-halfBody .framePage-article {
  background-color: #fff;
  margin-right: 0.675rem;
  height: 100%;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
  width: 20%;
  min-width: 20rem;
}
.framePage-halfBody .framePage-article .title {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.framePage-halfBody .framePage-article .title h3 {
  font-size: 0.85rem;
}
.framePage-halfBody .framePage-article .title h3:before {
  content: "";
  border-left: 4px solid #4574f9;
  width: 4px;
  height: 16px;
  position: absolute;
  left: -14px;
}
.framePage-halfBody .framePage-article .title .title-button {
  padding: 0;
  font-size: 0.8rem;
}
.framePage-halfBody .framePage-article .searchBox {
  display: flex;
  margin: 15px 0;
}
.framePage-halfBody .framePage-article .searchqusetionBox {
  display: flex;
  flex-wrap: wrap;
}
.framePage-halfBody .framePage-article .searchqusetionBox div {
  width: 50%;
  display: flex;
  margin: 15px 0;
}
.framePage-halfBody .framePage-article .searchqusetionBox div span {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.framePage-halfBody .framePage-article .searchqusetionBox div span img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.framePage-halfBody .framePage-article .searchqusetionBox div .addclass {
  color: #4574f9;
}
.framePage-halfBody .framePage-article .titleMation {
  display: flex;
  flex-direction: column;
  padding: 0.9rem 0 1.8rem 0;
}
.framePage-halfBody .framePage-article .titleMation span:nth-child(2) {
  padding: 0.5rem 0;
}
.framePage-halfBody .framePage-article .btnBox {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
.list .list-item {
  background-color: #fafafa;
  padding: 1rem 0;
  margin-bottom: 0.5rem;
}
.list .list-item .subject {
  margin-left: 1rem;
  margin-right: 1.5rem;
}
.list .list-item .subject .subject-title {
  display: flex;
  justify-content: space-between;
}
.list .list-item .subject .subject-title .number {
  padding-left: 1rem;
}
.list .list-item .subject .subject-title img {
  width: 100px;
}
.list .list-item .subject .option {
  display: flex;
  padding: 0.75rem;
  justify-content: space-between;
}
.list .list-item .subject .option span {
  line-height: 2rem;
  width: auto;
  display: flex;
  align-items: center;
}
.list .list-item .subject .option span .qbimg {
  width: 5rem;
}
.img-boxs img {
  width: 100px;
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.informationmBox {
  display: flex;
  justify-content: space-between;
}
.el-divider--horizontal {
  margin: 12px 0;
}
.el-cascader {
  width: 100%;
}
.el-form-item__content /deep/ .el-upload {
  width: 100px;
  height: 148px !important;
}
.bannerBox .el-form-item__content /deep/ .df {
  display: flex;
}
.bannerBox .el-form-item__content .hide /deep/ .el-upload--picture-card {
  display: none;
}
.el-upload-list--picture-card /deep/ .el-upload-list__item-actions {
  z-index: 9999;
}
.showbtn {
  display: none;
}
.hoverShow :hover .showbtn {
  display: block;
}
 /deep/ .el-input--mini .el-input__inner {
  height: 20px!important;
  line-height: 20px!important;
}
 /deep/ .el-form-item__content {
  display: flex;
  align-items: center;
}
 /deep/ .el-form-item__content .img-el-upload {
  display: inline-block;
}
 /deep/ .el-form-item__content .img-el-upload .el-upload {
  margin-left: 15px;
  width: 60px;
  height: 60px !important;
}
.exportdialog .upload-workers {
  width: 80px;
  height: 35px;
}
.exportdialog .upload-workers .el-upload {
  width: 100%!important;
  height: 35px!important;
  border: none !important;
}
.exportdialog .upload-workers .el-upload .el-button {
  width: 100%!important;
  height: 32px!important;
  padding: 5px 0;
}
.exportdialog .el-dialog__title {
  font-size: 16px;
}
.exportdialog .export-box {
  width: 100%;
  margin-bottom: 20px;
}
.exportdialog .export-box h1 {
  padding: 0 5px 10px;
  display: flex;
  align-items: flex-end;
  font-size: 40px;
  border-bottom: 1px dashed #ccc;
}
.exportdialog .export-box h1 span {
  margin-left: 20px;
  font-size: 14px;
}
.exportdialog .export-box > div {
  padding-left: 30px;
}
.exportdialog .export-box .el-button.is-disabled,
.exportdialog .export-box .el-button.is-disabled:focus,
.exportdialog .export-box .el-button.is-disabled:hover {
  color: #fff;
  background: #c3c3c3;
  border: none;
}
.docsDialog .el-form-item {
  margin-bottom: 0.5rem;
}
 /deep/ .list .list-item .subject .option {
  flex-wrap: wrap;
  justify-content: start;
}
 /deep/ .list .list-item .subject .option span {
  width: 25%;
  word-wrap: break-word;
  word-break: break-all;
  align-items: flex-start;
  line-height: 1.5;
}
